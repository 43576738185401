<template>
  <el-upload
      ref="upload"
      :action="action"
      :accept="accept"
      :headers="headers"
      :class="{ 'image-upload' : is_limit }"
      :limit="limit"
      :file-list="fileList"
      :on-success="success"
      :on-error="error"
      :before-upload="before_upload"
      list-type="picture-card">
    <i class="el-icon-plus"></i>
    <div slot="tip" class="el-upload__tip">{{ `请上传300*190分辨率的图像，支持png、jpg、bmp格式,大小不超过${size_data}MB` }}</div>
    <div slot="file" slot-scope="{file}" style="width: 100%; height: 100%;" >
      <img
          class="el-upload-list__item-thumbnail"
          style="object-fit: cover;"
          :src="file.url" alt=""
      >
      <span class="el-upload-list__item-actions">
        <span
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
    </div>
  </el-upload>
</template>

<script>
export default {
  name: "index",
  props: {
    value: {
      type: String,
      default: () => ""
    },
    showData: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      action: `${process.env.VUE_APP_APIURL}ec/uploadeducationcontentcover`,
      accept: ".png,.jpg,.bmp",
      headers: {
        authorization: this.$store.state.token,
      },
      limit: 1,
      fileList: [],
      size_data: 5,
    }
  },
  watch: {
    showData: {
      handler(newVal) {
        if (newVal) {
          this.fileList = [{
              name: this.getFileName(newVal),
              url: newVal,
            }]
        }
      },
      immediate: true,
    },
  },
  computed: {
    size() {
      return this.size_data/1024/1024;
    },
    is_limit() {
      return this.filePath;
    },
    filePath: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input",value)
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    before_upload(file) {
      console.log(file);
      const isType = file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/bmp";
      const isLt = this.size < file.size;

      if (!isType) {
        this.$message({
          message: '文件上传格式不正确！',
          type: 'error'
        })
      }

      if (!isLt) {
        this.$message({
          message: `上传文件不能大于${this.size_data}MB！`,
          type: 'error'
        })
      }

      return isType && isLt;
    },
    // eslint-disable-next-line no-unused-vars
    success(res,file,fileList) {
      this.filePath = res.data;
    },
    error() {

    },
    handleRemove(file) {
      console.log(file)
      if (file.response) {
        this.delFn(file.response.data);
      }else if (!file.raw) {
        this.delFn(file.url);
      }
      this.$refs["upload"].clearFiles();
    },
    delFn(fileUrl) {
      this.$get("ec/deleducationcontent",{
        fileUrl
      })
          // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.filePath = "";
        })
    },
    getFileName(url) {
      let file = url.split("/");
      return file[file.length - 1];
    },
  }
}
</script>

<style lang="scss" scoped>
.image-upload{
  /deep/ .el-upload{
    display: none;
  }
}
</style>
